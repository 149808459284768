import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Input from "../elements/Input";

const propTypes = {
	...SectionProps.types,
	split: PropTypes.bool,
};
const defaultProps = {
	...SectionProps.defaults,
	split: false,
};

const Cta = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	split,
	...props
}) => {
	const outerClasses = classNames(
		"cta section center-content-mobile reveal-from-bottom",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames(
		"cta-inner section-inner",
		topDivider && "has-top-divider",
		bottomDivider && "has-bottom-divider",
		split && "cta-split"
	);

	return (
		<section {...props} id="support" className={outerClasses}>
			<div className="container">
				<div className={innerClasses}>
					<div className="cta-slogan">
						<h3 className="m-0">Need support?</h3>
					</div>
					<div className="cta-action">
						<h5>
							email us at:{" "}
							<a href="mailto: gobo.support@screeningroom.com">
								gobo.support@screeningroom.com
							</a>
						</h5>
					</div>
				</div>
			</div>
		</section>
	);
};

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
