import React from "react";
// import sections
import TitleHero from "../components/sections/TitleHero";
import Hero from "../components/sections/Hero";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import FeaturesSplit from "../components/sections/FeaturesSplit";
import Testimonial from "../components/sections/Testimonial";
import Cta from "../components/sections/Cta";

const Title = () => {
	return (
		<>
			<TitleHero className="illustration-section-01" />
			<Hero className="illustration-section-01" titlePage={true} />

			<FeaturesTiles />
			{/* <FeaturesSplit
				invertMobile
				topDivider
				imageFill
				className="illustration-section-02"
			/> */}
			{/* <Testimonial topDivider /> */}
		</>
	);
};

export default Title;
