import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import eye from "./../../assets/images/eye-off-outline.svg";
import compass from "./../../assets/images/compass.svg";
import bookmark from "./../../assets/images/bookmark.svg";
import search from "./../../assets/images/search.svg";
import Cta from "./Cta";

const propTypes = {
	...SectionTilesProps.types,
};

const defaultProps = {
	...SectionTilesProps.defaults,
};
const FeaturesTiles = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	pushLeft,
	...props
}) => {
	const outerClasses = classNames(
		"features-tiles section",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames(
		"features-tiles-inner section-inner pt-0",
		topDivider && "has-top-divider",
		bottomDivider && "has-bottom-divider"
	);

	const tilesClasses = classNames(
		"tiles-wrap center-content",
		pushLeft && "push-left"
	);

	const sectionHeader = {
		title: "recommendations that matter", //"tv filtered by friends",
		paragraph:
			"Track and review your favorite TV shows and movies with a quick like or dislike feature, or write a longer review. Connect with your friends to find out what they are currently watching. Easily bookmark a title so you know what to watch next time you are looking for some entertainment.",
	};

	return (
		<section {...props} className={outerClasses}>
			<div className="container">
				<div className={innerClasses}>
					<SectionHeader data={sectionHeader} className="center-content" />
					<div className={tilesClasses}>
						<div className="tiles-item reveal-from-bottom">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={eye}
											alt="Features tile icon 01"
											width={32}
											height={32}
										/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">Private</h4>
									<p className="m-0 text-sm">
										Your reviews are hidden to the public and can only be viewed
										by the friends you have added to your closed network.
									</p>
								</div>
							</div>
						</div>

						<div
							className="tiles-item reveal-from-bottom"
							data-reveal-delay="200"
						>
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={compass}
											alt="Features tile icon 02"
											width={32}
											height={32}
										/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">Discover</h4>
									<p className="m-0 text-sm">
										Easily search a TV show or movie to find out which platforms
										have it available to stream.
									</p>
								</div>
							</div>
						</div>

						<div
							className="tiles-item reveal-from-bottom"
							data-reveal-delay="400"
						>
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={search}
											alt="Features tile icon 03"
											width={32}
											height={32}
										/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">Narrow your search</h4>
									<p className="m-0 text-sm">
										filter by streaming platform or by content type.
									</p>
								</div>
							</div>
						</div>

						<div className="tiles-item reveal-from-bottom">
							<div className="tiles-item-inner">
								<div className="features-tiles-item-header">
									<div className="features-tiles-item-image mb-16">
										<Image
											src={bookmark}
											alt="Features tile icon 04"
											width={32}
											height={32}
										/>
									</div>
								</div>
								<div className="features-tiles-item-content">
									<h4 className="mt-0 mb-8">Bookmark</h4>
									<p className="m-0 text-sm">
										Bookmark titles that you want to watch in the future so you
										don’t forget a great recommendation!
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Cta />
		</section>
	);
};

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
