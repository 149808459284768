import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import { useHistory } from "react-router-dom";

const propTypes = {
	...SectionProps.types,
};

const defaultProps = {
	...SectionProps.defaults,
};
const params = new URLSearchParams(window.location.search);
const user = params.get("user");
const poster = params.get("poster");
const title = params.get("title");

const TitleHero = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	...props
}) => {
	const [videoModalActive, setVideomodalactive] = useState(false);
	const history = useHistory();

	if (
		window.location.pathname === "/title" &&
		(user === null || poster === null || title === null)
	)
		history.push("/");
	const openModal = (e) => {
		e.preventDefault();
		setVideomodalactive(true);
	};

	const closeModal = (e) => {
		e.preventDefault();
		setVideomodalactive(false);
	};

	const outerClasses = classNames(
		"hero section center-content",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames(
		"hero-inner section-inner",
		topDivider && "has-top-divider",
		bottomDivider && "has-bottom-divider"
	);

	return (
		<section {...props} className={outerClasses}>
			<div className="container-sm">
				<div className={innerClasses}>
					<div className="hero-content">
						<h2
							className="mt-0 mb-16 reveal-from-bottom"
							data-reveal-delay="200"
						>
							{user} wants you to watch{" "}
							<span className="text-color-primary">{title}</span>
						</h2>

						<div className="container-xs">
							<p
								className="m-0 mb-32 reveal-from-bottom"
								data-reveal-delay="400"
							>
								Never forget to watch a show again! Download the Gobo app so you
								can bookmark {title} and keep track of what you want to watch
								next. Plus, get personalized recommendations from {user} and all
								your other friends on the app.
							</p>
						</div>
					</div>
					<div
						className="hero-figure reveal-from-bottom illustration-element-01"
						data-reveal-value="20px"
						data-reveal-delay="800"
					>
						<Image
							className="has-shadow"
							src={poster}
							alt="Hero"
							width={200}
							height={200}
						/>
					</div>
				</div>
				<ButtonGroup>
					<Button
						tag="a"
						color="primary"
						wideMobile
						href="https://apps.apple.com/us/app/gobo-streaming-recs/id1571220687"
					>
						Download now
					</Button>
				</ButtonGroup>
			</div>
		</section>
	);
};

TitleHero.propTypes = propTypes;
TitleHero.defaultProps = defaultProps;

export default TitleHero;
