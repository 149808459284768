import React, { useState } from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import ButtonGroup from "../elements/ButtonGroup";
import Button from "../elements/Button";
import Image from "../elements/Image";
import Modal from "../elements/Modal";

const propTypes = {
	...SectionProps.types,
};

const defaultProps = {
	...SectionProps.defaults,
};

const Hero = ({
	className,
	topOuterDivider,
	bottomOuterDivider,
	topDivider,
	bottomDivider,
	hasBgColor,
	invertColor,
	titlePage,
	...props
}) => {
	const [videoModalActive, setVideomodalactive] = useState(false);

	const openModal = (e) => {
		e.preventDefault();
		setVideomodalactive(true);
	};

	const closeModal = (e) => {
		e.preventDefault();
		setVideomodalactive(false);
	};
	const outerClasses = classNames(
		"hero section center-content",
		topOuterDivider && "has-top-divider",
		bottomOuterDivider && "has-bottom-divider",
		hasBgColor && "has-bg-color",
		invertColor && "invert-color",
		className
	);

	const innerClasses = classNames(
		"hero-inner section-inner",
		topDivider && "has-top-divider",
		bottomDivider && "has-bottom-divider"
	);

	return (
		<section {...props} className={outerClasses}>
			<div className="container-sm">
				<div className={innerClasses}>
					<div className="hero-content">
						<h2
							className="mt-0 mb-16 reveal-from-bottom"
							data-reveal-delay="200"
						>
							TV filtered by <span className="text-color-primary">friends</span>
						</h2>

						<div className="container-xs">
							<p
								className="m-0 mb-32 reveal-from-bottom"
								data-reveal-delay="400"
							>
								Having trouble deciding what to watch tonight? Gobo is a social
								networking platform that lets friends review and recommend
								content available on all the major streaming platforms
							</p>
							<div className="reveal-from-bottom" data-reveal-delay="600">
								{!titlePage ? (
									<ButtonGroup>
										<Button
											tag="a"
											color="primary"
											wideMobile
											href="https://apps.apple.com/us/app/gobo-streaming-recs/id1571220687"
										>
											Download now
										</Button>
									</ButtonGroup>
								) : null}
							</div>
						</div>
					</div>
					<div
						className="hero-figure reveal-from-bottom illustration-element-01"
						data-reveal-value="20px"
						data-reveal-delay="800"
					>
						<Image
							className="has-shadow"
							src={require("./../../assets/images/homescreen.png")}
							alt="Hero"
							width={300}
							height={300}
						/>
					</div>
					<Modal
						id="video-modal"
						show={videoModalActive}
						handleClose={closeModal}
						video="https://player.vimeo.com/video/174002812"
						videoTag="iframe"
					/>
				</div>
			</div>
		</section>
	);
};

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
